import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgModule } from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { StringFilterByModule } from "../../pipes/string-filter-by/string-filter-by.module";
import { VirtualScrollSelectComponent } from "./component/virtual-scroll-select.component";

@NgModule({
  declarations: [VirtualScrollSelectComponent],
  imports: [
    FuseSharedModule,
    NgxMatSelectSearchModule,
    StringFilterByModule,

    ScrollingModule,

    MatSelectModule
  ],
  exports: [VirtualScrollSelectComponent]
})
export class VirtualScrollSelectModule {}
