import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "bulk-processed",
  templateUrl: "./processed.component.html",
  styleUrls: ["./processed.component.scss"]
})
export class ProcessedComponent implements OnInit {
  @Input() title: string;
  @Input() response: any;
  @Input() success: boolean;
  processedOrders: number = 0;

  calculateProcessedOrders() {
    if (this.title === "Weight Discrepancy") {
      const { processedAwb, awbNotFound, awbNotUpdated } = this.response;

      this.processedOrders =
        processedAwb -
        ((awbNotFound?.length || 0) + (awbNotUpdated?.length || 0));
    } else if (this.title === "COD Remittance") {
      const { processedAwb, alreadyDone, notUpdated, orderToRemittNotFound } =
        this.response;

      this.processedOrders =
        processedAwb -
        ((alreadyDone?.length || 0) +
          (notUpdated?.length || 0) +
          (orderToRemittNotFound?.length || 0));
    }
  }

  ngOnInit() {
    this.calculateProcessedOrders();
  }
}
