import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "bulk-processing",
  templateUrl: "./processing.component.html",
  styleUrls: ["./processing.component.scss"]
})
export class ProcessingComponent {
  @Input() progressValue: number;
  @Input() title: string;
  @Input() totalOrder: number;
  @Input() processedOrder: number;
  constructor() {}

  ngOnChanges(): void {
    this.progressValue = this.progressValue <= 100 ? this.progressValue : 100;
  }
}
