import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { NdrLogComponent } from "./component/ndr-log.component";

@NgModule({
  declarations: [NdrLogComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class NdrLogModule {}
