import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-supported-browser-list',
  templateUrl: './supported-browser-list.component.html',
  styleUrls: [
    "./supported-browser-list.component.scss",
    "./../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class SupportedBrowserListComponent {}
