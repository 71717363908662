import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FullScreenLoaderService {
    // declarations
    private _visible: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {}

    /**
     * Visible
     */
    get visible(): Observable<any> {
        return this._visible.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the loader
     */
    show(): void {
        this._visible.next(true);
    }

    /**
     * Hide the loader
     */
    hide(): void {
        this._visible.next(false);
    }
}
