import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RequestService {
  refreshOrder: Subject<any> = new Subject();

  constructor(private _http: HttpClient) {}

  refreshOrders = () => this.refreshOrder.next(true);

  getWDOrders = (apiUrl: string, body) => this._http.post(apiUrl, body);

  replyWeightDesc = (body: any) =>
    this._http.post("admin/order/replyWeightDesc", body);

  //for sending weight discripency csv data to backend
  markDesc = ({ url, body }) => this._http.post(url, body);

  getVasRequests = (apiUrl: string) => this._http.get(apiUrl);

  updateVasRequest = (apiUrl: string, body) => this._http.post(apiUrl, body);

  renderCodVerifyPage = (body: any) =>
    this._http.post("admin/customer/cod-verify/render", body);

  getReattemptOrders = (apiUrl: string, body) => this._http.post(apiUrl, body);

  getTrackingFlaggedOrders = (apiUrl: string, body) =>
    this._http.post(apiUrl, body);
}
