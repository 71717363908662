<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/shippigo.svg" />
        </div>
      </div>

      <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
    </div>

    <div class="toolbar-separator"></div>

    <!-- List all active notifications -->
    <toolbar-notification *ngIf="isUserLoggedIn"></toolbar-notification>

    <div class="toolbar-separator"></div>

    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg"> -->
          <span class="avatar avatar-circle mr-0 mr-sm-8 text-uppercase">
            {{ profile?.firstName?.charAt(0)
            }}{{ profile?.lastName?.charAt(0) }}
          </span>
          <!-- <span class="username mr-12" fxHide fxShow.gt-sm>Charlie Adams</span> -->
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" xPosition="before" [overlapTrigger]="false">
        <button mat-menu-item routerLink="settings/profile">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>

        <button mat-menu-item [matMenuTriggerFor]="colorMenu">
          <mat-icon>color_lens</mat-icon>
          <span>Theme</span>
        </button>

        <mat-menu #colorMenu="matMenu">
          <button mat-menu-item (click)="setTheme('theme-default')">
            Light
            <mat-icon color="accent" class="align-icon" *ngIf="lightTheme"
              >check_circle_outline</mat-icon
            >
          </button>
          <button mat-menu-item (click)="setTheme('theme-blue-gray-dark')">
            Dark
            <mat-icon color="accent" class="align-icon" *ngIf="!lightTheme"
              >check_circle_outline</mat-icon
            >
          </button>
        </mat-menu>

        <ng-container *ngIf="tenants?.length">
          <button mat-menu-item [matMenuTriggerFor]="tenantMenu">
            <mat-icon>people</mat-icon>
            <span>Switch Tenant</span>
          </button>
        </ng-container>

        <mat-menu #tenantMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let tenant of tenants"
            (click)="switchToOtherTenant(tenant?.name)"
          >
            {{ tenant?.sName }}
            <mat-icon
              color="accent"
              class="align-icon"
              *ngIf="tenant?.name === currentTenant"
              >check_circle_outline</mat-icon
            >
          </button>
        </mat-menu>

        <!-- <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <!-- <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <button
                mat-button
                fxHide
                fxShow.gt-xs
                class="language-button"
                [matMenuTriggerFor]="languageMenu"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img
                        class="flag mr-8"
                        [src]="
                            'assets/icons/flags/' +
                            selectedLanguage.flag +
                            '.png'
                        "
                    />
                    <span class="iso text-uppercase">{{
                        selectedLanguage.id
                    }}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button
                    mat-menu-item
                    *ngFor="let lang of languages"
                    (click)="setLanguage(lang)"
                >
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img
                            class="flag mr-16"
                            [src]="'assets/icons/flags/' + lang.flag + '.png'"
                        />
                        <span class="iso">{{ lang.title }}</span>
                    </span>
                </button>
            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel"
            >
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && rightNavbar"
                fxHide
                fxShow.gt-xs
            ></div>

            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->
    </div>
  </div>
</mat-toolbar>
