import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2
} from "@angular/core";

@Directive({
  selector: "[ProgressStatus]"
})
export class ProgressStatusDirective implements OnInit, OnChanges {
  @Input() value;

  circularProgress(maxPercent, percent, colorBar, colorBack): any {
    const increment = 360 / maxPercent;
    const half = Math.floor(maxPercent / 2);
    let background = "";

    if (percent < half) {
      const next = 90 + increment * percent;
      const nextdeg = `${next}deg`;

      background = `linear-gradient(90deg, ${colorBack} 50%, transparent 50%, transparent),
      linear-gradient(${nextdeg}, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
    } else {
      const next = -90 + increment * (percent - half);
      const nextdeg = `${next}deg`;

      background = `linear-gradient(${nextdeg}, ${colorBar} 50%, transparent 50%, transparent),
        linear-gradient(270deg, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
    }
    return background;
  }

  constructor(private ele: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      this.ele.nativeElement,
      "background",
      this.circularProgress(100, this.value, "#C0C0C0", "#E0E0E0")
    );
  }

  ngOnChanges(): void {
    this.renderer.setStyle(
      this.ele.nativeElement,
      "background",
      this.circularProgress(100, this.value, "#03A9F4", "#E0E0E0")
    );
  }
}
