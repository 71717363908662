import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FullScreenLoaderService } from "../full-screen-loader.service";

@Component({
  selector: "full-screen-loader",
  templateUrl: "./full-screen-loader.component.html",
  styleUrls: ["./full-screen-loader.component.scss"]
})
export class FullScreenLoaderComponent implements OnInit {
  // declarations
  visible = false;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private _fullScreenLoader: FullScreenLoaderService) {}

  ngOnInit(): void {
    // Visible
    this._fullScreenLoader.visible
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((visible) => {
        this.visible = visible;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
