import { NgModule } from '@angular/core';
import { UserDetailsComponent } from "./component/user-details.component";
import { FuseSharedModule } from '@fuse/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [UserDetailsComponent],
  imports: [
    PipesModule,
    FuseSharedModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule
  ]
})
export class UserDetailsModule { }
