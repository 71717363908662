import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportSubscribersComponent } from "./component/report-subscribers.component";
import { MatToolbar, MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { FuseSharedModule } from "@fuse/shared.module";
import { PipesModule } from "../../pipes/pipes.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { TextFieldModule } from "@angular/cdk/text-field";

@NgModule({
  declarations: [ReportSubscribersComponent],
  imports: [
    CommonModule,
    // fuse
    FuseSharedModule,
    // shared
    PipesModule,
    // material
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    ErrorMessageModule,
    TextFieldModule
  ]
})
export class ReportsSubscribersModule {}
