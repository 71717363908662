import { NgModule } from "@angular/core";
import { UpdateOrderDataComponent } from "./component/update-order-data.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { PipesModule } from "../../pipes/pipes.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { StringFilterByModule } from "../../pipes/string-filter-by/string-filter-by.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DigitsModule } from "../../directives/digits/digits.module";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  declarations: [UpdateOrderDataComponent],
  imports: [
  
    FuseSharedModule,
    ErrorMessageModule,
    PipesModule,
    DigitsModule,
    StringFilterByModule,
    NgxMatSelectSearchModule,


    // material
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule
  ]
})
export class UpdateOrderDataModule {}
