import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadWeightDiscrepancyOrdersComponent } from "./download-weight-discrepancy-orders/download-weight-discrepancy-orders.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [DownloadWeightDiscrepancyOrdersComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class DownloadWeightDiscrepancyOrdersModule {}
