import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrimInputValueDirective } from "./trim-input-value.directive";

@NgModule({
  declarations: [TrimInputValueDirective],
  imports: [CommonModule],
  exports: [TrimInputValueDirective]
})
export class TrimInputValueModule {}
