<h2 class="title">
  Please wait!<br />
  While we are uploading {{ title }}.
</h2>

<!-- Circular Progress -->
<div class="circular-progress" ProgressStatus [value]="progressValue">
  <div class="circular-inside">{{ progressValue | number: "1.0-0" }}%</div>
</div>

<!-- Order Status -->
<div class="comments">
  <h3 class="m-0">
    Processed {{ processedOrder }} Orders out of {{ totalOrder }} Orders
  </h3>
</div>
