import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class TransactionsApiService {
  transactionsUpdated: Subject<any> = new Subject();
  remitDetailsUpdated: Subject<any> = new Subject();

  constructor(private _http: HttpClient) {}

  refreshRemitDetails() {
    this.remitDetailsUpdated.next("data updated");
  }

  refreshTransactionsTable() {
    this.transactionsUpdated.next("data updated");
  }

  getTransactions = (page: number, body) => {
    const apiPathObject = {
      summary: "admin/transaction/transactions",
      recharge: "admin/transaction/getNeftRequests",
      rechargeCod: "admin/transaction/getNeftRequests",
      fetchPendingRecharge: "admin/transaction/fetchPendingRecharges"
    };
    let apiPath = apiPathObject[body.callApiFor];
    const apiUrl =
      body.callApiFor === "recharge" || body.callApiFor === "rechargeCod"
        ? `${apiPath}`
        : `${apiPath}/${page}`;

    return this._http.post(apiUrl, body);
  };

  approveRecharge = (body) =>
    this._http.post("admin/transaction/approveRecharge", body);

  accumulatedBalance = (body: any) =>
    this._http.post("admin/customer/finalBalance", body);

  //for wallet recharge
  walletRecharge = (body) =>
    this._http.post("admin/transaction/WalletTransaction", body);

  //to get remit orders
  remittOrders = (page, body) =>
    this._http.post(`admin/cod/remittOrders/${page}`, body);

  // to get remit stats of shippigo/admin
  remittStats = () => this._http.get("admin/cod/remittStats");

  // to upload user/admin remit details using csv
  remittCsvUpload = (data: any) => this._http.post(data.url, data.body);

  generateDuplicateInvoice = (body: any) =>
    this._http.post("admin/order/generate-csv/duplicateInvoice", body);
}
