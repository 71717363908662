import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "./../../../../../@fuse/shared.module";
import { SupportedBrowserListComponent } from "./supported-browser-list.component";

@NgModule({
  declarations: [SupportedBrowserListComponent], 
  imports: [
    FuseSharedModule,

    // material module
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatToolbarModule,
    MatDialogModule
  ]
})
export class SupportedBrowserListModule {}
