import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationLogComponent } from "./component/notification-log.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [NotificationLogComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule
  ]
})
export class NotificationLogModule {}
