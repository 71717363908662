import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class FetchUsersService {
  userList = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getUsers(body = { option: "search" }) {
    this._http.post("admin/customer/getAllUsers", body).subscribe(
      (res: any) => {
        if (res.success) {
          // this.userList.next(this.sortUsers(res.users));
          const sortedUsers = this.sortUsers(res.users);
          const usersWithDisplayName = this.addDisplayName(sortedUsers);
          this.userList.next(usersWithDisplayName);
        } else {
          this.userList.next([]);
          this._notification.show(res.msg);
        }
      },
      (err) => {
        this.userList.next([]);
        throw err;
      }
    );
  }
  addDisplayName(users): any {
    for (let user of users) {
      const searchName: string = `${user.brandName || user.companyName} -
      ${user.email} - ${user.firstName} ${user.lastName}`;

      user.displayName = searchName;
    }
    return users;
  }

  sortUsers(users): any {
    const sortedUsers = users.sort((a, b) => {
      let userA = a.brandName?.toUpperCase() || a.companyName?.toUpperCase(),
        userB = b.brandName?.toUpperCase() || b.companyName?.toUpperCase();

      if (userA > userB) {
        return 1;
      } else if (userA < userB) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedUsers;
  }
}
