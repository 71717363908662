import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToolbarNotification } from "../shared/interfaces/toolbar-notificaton";

@Injectable({
  providedIn: "root"
})
export class ToolbarNotificationService {
  onNotificationUpdated: Subject<any> = new Subject();

  private _initialNotification: any[] = [];

  constructor(private _http: HttpClient) {}

  /**
   * Add a notification
   */
  add(notification: ToolbarNotification): void {
    const notificationAdded = this._initialNotification.some(
      (ele) => ele._id === notification._id
    );

    // if notification not added ,then only push the notification to initial notification array
    if (!notificationAdded) {
      this._initialNotification.push(notification);
      this.onNotificationUpdated.next(true);
    }
  }

  /**
   * remove notification
   */
  remove(id): void {
    const index = this._initialNotification.findIndex(
      (iNotification: ToolbarNotification) => iNotification._id === id
    );

    if (index === -1) return;

    this._initialNotification.splice(index, 1);
    this.onNotificationUpdated.next(true);
  }

  /**
   * get all notifications
   */

  get(): any {
    return this._initialNotification;
  }

  /**
   * Clear all notifications
   */

  clear(): void {
    this._initialNotification = [];
    this.onNotificationUpdated.next(true);
  }

  removeClearableNotifications(notToBeCleared: any[] = []) {
    this._initialNotification = notToBeCleared;
    this.onNotificationUpdated.next(true);
  }

  /**
   * dismss notification
   */

  dismiss(id): void {
    const index = this._initialNotification.findIndex(
      (notification: ToolbarNotification) => notification._id === id
    );
    if (index === -1) return;
    this._initialNotification.splice(index, 1);
    this.onNotificationUpdated.next(true);
  }

  getNotifications() {
    return this._http.get("admin/notification/all");
  }

  markAsRead(body) {
    return this._http.post("admin/notification/read", body);
  }
}
