import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CloseOrderComponent } from "./component/close-order.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatSelectModule } from "@angular/material/select";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
  declarations: [CloseOrderComponent],
  imports: [
    FuseSharedModule,
    ErrorMessageModule,
    // material
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    PipesModule
  ]
})
export class CloseOrderModule {}
