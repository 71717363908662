import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { RouterModule, Routes } from "@angular/router";
import { LoginGuard } from "./main/shared/guards/login.guard";
import { AuthenticationGuard } from "./main/shared/guards/authentication.guard";
import { DynamicModulePermissionGuard } from "./main/shared/guards/dynamic-module-permission.guard";
import { ActuatorHealthComponent } from "./main/shared/components/actuator-health/actuator-health.component";

const appRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login"
  },

  {
    path: "dashboard",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard, DynamicModulePermissionGuard],
    loadChildren: () =>
      import("./main/dashboard/dashboard.module").then((m) => m.DashboardModule)
  },
  {
    path: "login",
    canActivate: [LoginGuard],
    loadChildren: () =>
      import("./main/pages/login/login.module").then((m) => m.LoginModule)
  },
  {
    path: "actions",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/actions/actions.module").then((m) => m.ActionsModule)
  },
  {
    path: "orders",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/orders/orders.module").then((m) => m.OrdersModule)
  },
  {
    path: "users",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/users/users.module").then((m) => m.UsersModule)
  },
  // {
  //   path: "rate-management",
  //   canLoad: [AuthenticationGuard],
  //   canActivateChild: [AuthenticationGuard],
  //   loadChildren: () =>
  //     import("./main/rate-management/rate-management.module").then(
  //       (m) => m.RateManagementModule
  //     )
  // },
  {
    path: "settings",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard, DynamicModulePermissionGuard],
    loadChildren: () =>
      import("./main/settings/settings.module").then((m) => m.SettingsModule)
  },
  {
    path: "request",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/request/request.module").then((m) => m.RequestModule)
  },
  {
    path: "transactions",
    canLoad: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import("./main/transactions/transactions.module").then(
        (m) => m.TransactionsModule
      )
  },
  // {
  //   path: "others",
  //   canLoad: [AuthenticationGuard],
  //   canActivateChild: [AuthenticationGuard, DynamicModulePermissionGuard],
  //   loadChildren: () =>
  //     import("./main/others/others.module").then((m) => m.OthersModule)
  // },
  {
    path: "actuator/health",
    component: ActuatorHealthComponent
  },
  {
    path: "**",
    loadChildren: () =>
      import("./main/pages/error404/error404.module").then(
        (m) => m.Error404Module
      )
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QuicklinkModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: QuicklinkStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
