import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SupportedBrowserListComponent } from "./../../../../main/shared/modals/supported-browser-list/supported-browser-list.component";

@Component({
  selector: "app-banner-message",
  templateUrl: "./banner-message.component.html",
  styleUrls: ["./banner-message.component.scss"]
})
export class BannerMessageComponent {
  @Output() hideBannerMessage = new EventEmitter();

  constructor(private _dialog: MatDialog) {}

  closeBanner() {
    this.hideBannerMessage.emit();
  }

  openSupportedBrowsersModal() {
    this._dialog.open(SupportedBrowserListComponent, { 
      panelClass: "small-modal"
    });
  }
}
