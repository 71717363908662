import { Injectable } from "@angular/core";
import {
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";

@Injectable({
  providedIn: "root"
})
export class AuthenticationGuard implements CanActivateChild, CanLoad {
  constructor(private _loginService: LoginService, private _router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guardLogic();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.guardLogic();
  }

  guardLogic(): Observable<boolean> | boolean {
    const userLoggedIn = !!this._loginService.getToken();

    if (!userLoggedIn) {
      return of(false).pipe(
        tap(() => {
          this._router.navigate(["/"]);
        })
      );
    }

    return true;
  }
}
