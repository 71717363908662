import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FetchFilesService } from "app/main/shared/services/fetch-files.service";
import { OrdersDataService } from "app/main/shared/services/orders-data.service";
import { SidebarNavigationService } from "app/main/shared/services/sidebar-navigation.service";
@Component({
  selector: "app-request-feedback",
  templateUrl: "./request-feedback.component.html",
  styleUrls: [
    "./request-feedback.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class RequestFeedbackComponent {
  performedBy = {
    0: "Consignee",
    1: "Merchant",
    2: "Admin"
  };

  trackType = {
    0: "In Transit",
    1: "NDR",
    2: "RTO",
    3: "Return"
  };

  type = {
    neighbour: "Neighbour",
    alternativeaddress: "Alternative Address",
    security: "Security",
    relative: "Relative"
  };

  status = {
    0: "Approved",
    "-1": "Reject",
    1: "Pending"
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sidebarNavigationService: SidebarNavigationService,
    private _dialogRef: MatDialogRef<RequestFeedbackComponent>,
    private _fetchFilesService: FetchFilesService,
    private _orderDataService: OrdersDataService
  ) {
    let { SG_ORDERID, heading, unread } = this.data;
    let type = heading === "Request" ? "requests" : "feedback";

    if (!!unread) {
      this.sidebarNavigationService.viewFeedbackRequest({
        type,
        SG_ORDERID
      });
    }
  }

  fetchRequestDocument(fileName, SGID) {
    const body = {
      SGID,
      fileName,
      type: "buffer"
    };
    this._fetchFilesService.fetchAndDownloadFile(body);
  }

  closeModal() {
    if (this.data?.unread) {
      this._orderDataService.refreshOrders();
    }
    this._dialogRef.close(true);
  }
}
