<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Uploading {{ title }}</span>
      <button mat-icon-button aria-label="Close dialog" (click)="closeDialog()">
        <mat-icon [ngClass]="{ 'mb-12': bulkProcessStatus === 'processing' }">{{
          closeIcon
        }}</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pt-12 m-0">
    <bulk-processing
      *ngIf="bulkProcessStatus === 'processing'; else processed"
      [progressValue]="progressValue"
      [title]="title"
      [totalOrder]="totalOrder"
      [processedOrder]="processedOrder"
    ></bulk-processing>

    <ng-template #processed>
      <bulk-processed
        [title]="title"
        [response]="response"
        [success]="success"
      ></bulk-processed>
    </ng-template>
  </div>
</div>
