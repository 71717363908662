import { NgModule } from "@angular/core";
import { CheckServiceabilityComponent } from "./component/check-serviceability.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  declarations: [CheckServiceabilityComponent],
  imports: [
    // fuse
    FuseSharedModule,
    // shared
    ErrorMessageModule,
    // material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule
  ]
})
export class CheckServiceabilityModule {}
