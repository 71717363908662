import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmationModalForRequestsComponent } from "./confirmation-modal-for-requests/confirmation-modal-for-requests.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { PipesModule } from "../../pipes/pipes.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [ConfirmationModalForRequestsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    MatToolbarModule,
    PipesModule,
    MatDialogModule
  ]
})
export class ConfirmationModalForRequestsModule {}
