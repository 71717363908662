import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmUpdateRequestComponent } from "./component/confirm-update-request.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [ConfirmUpdateRequestComponent],
  imports: [
    // fuse
    FuseSharedModule,

    ErrorMessageModule,

    // material
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class ConfirmUpdateRequestModule {}
