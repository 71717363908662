import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from "@angular/core";
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule
} from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { AppComponent } from "app/app.component";
import { fuseConfig } from "app/fuse-config";
import { LayoutModule } from "app/layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { CustomErrorHandler } from "./main/shared/classes/custom-error-handler";
import { CustomNotificationComponent } from "./main/shared/components/custom-notification/custom-notification.component";
import { LogoutMsgComponent } from "./main/shared/components/logout-msg/logout-msg.component";
import { HttpRequestRetryInterceptor } from "./main/shared/interceptors/retry-http.interceptor";
import { TokenInterceptor } from "./main/shared/interceptors/token.interceptor";
import { UnauthorizedTokenInterceptor } from "./main/shared/interceptors/unauthorized-token.interceptor";
import { FullScreenLoaderModule } from "./main/shared/modules/full-screen-loader/full-screen-loader.module";
import { SharedModule } from "./main/shared/shared.module";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const INDIAN_DATE_FORMAT = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD-MM-YYYY",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [AppComponent, CustomNotificationComponent, LogoutMsgComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,

    //Shared
    SharedModule,
    FullScreenLoaderModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule
  ],

  bootstrap: [AppComponent],
  providers: [
    // Providing locale id
    { provide: LOCALE_ID, useValue: "en-IN" },
    // Datepicker with custom formats
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: INDIAN_DATE_FORMAT },
    // Http inteceptors
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestRetryInterceptor,
      multi: true
    },
    // Global error handler
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    // Sentry track performance
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
})
export class AppModule {}
