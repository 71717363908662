import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "environments/environment";
import { FullScreenLoaderService } from "../modules/full-screen-loader/full-screen-loader.service";
import { NotificationService } from "../services/notification.service";

function initializeSentry(): void {
  const { apiEndPoint, mode, appVersion } = environment;

  Sentry.init({
    dsn: "https://f7fbe7613ef3e73f6391de601ec20ccb@o4505622855286784.ingest.sentry.io/4505640237400064",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [apiEndPoint],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    environment: mode,
    release: appVersion,
    sampleRate: 0.5,
    tracesSampleRate: 0.5
  });
}

const _logger = Sentry.createErrorHandler({
  logErrors: false
});

const chunkFailedRegex = /ChunkLoadError/i;

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private _notification: NotificationService,
    private _fullScreenLoader: FullScreenLoaderService,
    private _zone: NgZone
  ) {
    if (environment.logErr) initializeSentry();
  }

  /**
   * HTTP response status codes
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
   * Informational responses (100–199),
   * Successful responses (200–299),
   * Redirects (300–399),
   * Client errors (400–499),
   * and Server errors (500–599).
   */

  handleError(error: any): void {
    if (chunkFailedRegex.test(error.message)) {
      return this.handleChunkError();
    }
    this.hideLoaderAndShowNotification();
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpErrors(error);
    }

    this.consoleAndLogError(error);
  }

  consoleAndLogError(error: any): void {
    // logging & consoling error
    const { logErr, consoleErr } = environment;

    if (consoleErr) console.error(error);
    // if (logErr) _logger.handleError(error);
  }

  defaultNotification = () => {
    this._notification.show("Sorry, Something went wrong.");
  };

  hideLoaderAndShowNotification(
    notification: () => void = this.defaultNotification
  ): void {
    this._zone.run(() => {
      // hiding fullscreen loader
      this._fullScreenLoader.hide();

      // showing error to user
      notification();
    });
  }

  handleChunkError(): void {
    const chunkErrorNotification = () => {
      this._notification
        .show(
          "Error in loading module. Please reload and try again.",
          "Reload",
          NaN
        )
        .onAction()
        .subscribe(() => window.location.reload());
    };

    this.hideLoaderAndShowNotification(chunkErrorNotification);
  }

  handleHttpErrors(error: any): void {
    const { status, statusText } = error;

    let errorMsg = "";

    if (status >= 400 && status <= 499) {
      errorMsg = `Client Error ${status}: ${statusText}`;
    } else if (status >= 500 && status <= 599) {
      errorMsg = `Server Error ${status}: ${statusText}`;
    } else {
      errorMsg = `Error ${status}: ${statusText}`;
    }
    console.warn(errorMsg);
  }
}
