import { NgModule } from "@angular/core";
import { UpdateTrackingComponent } from "./component/update-tracking.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { TrimInputValueModule } from "app/main/shared/directives/trim-input-value/trim-input-value.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { PipesModule } from "../../pipes/pipes.module";
import { MatDatepickerModule } from "@angular/material/datepicker";

@NgModule({
    declarations: [UpdateTrackingComponent],
    imports: [
        // fuse
        FuseSharedModule,

        // shared
        ErrorMessageModule,
        TrimInputValueModule,

        //pipes
        PipesModule,

        // material
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
    ],
})
export class UpdateTrackingModule {}
