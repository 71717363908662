import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LoginService } from "../services/login.service";

@Injectable()
export class UnauthorizedTokenInterceptor implements HttpInterceptor {
  constructor(private _loginService: LoginService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const { success, status } = event?.body;

          if (!success && status === 401) {
            this._loginService.logout();
          }
        }
      })
    );
  }
}
