import { NgModule } from "@angular/core";
import { SubAdminPermissionsComponent } from "./component/sub-admin-permissions.component";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDividerModule } from "@angular/material/divider";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  declarations: [SubAdminPermissionsComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule
  ]
})
export class SubAdminPermissionsModule {}
