import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from "@angular/material/snack-bar";
import { CustomNotification } from "../interfaces/custom-notification";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  // declarations
  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  defalutData = {
    message: { text: "Please pass message data to component.", classes: [] }
  };

  constructor(private _snackBar: MatSnackBar) {}

  // pass NaN in duration for unlimited time notification
  show(
    message: string,
    action: string = "Close",
    duration: number = 3000
  ): MatSnackBarRef<any> {
    const snackBar = this._snackBar.open(message, action, {
      duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
    return snackBar;
  }

  showComponent(
    component,
    data: CustomNotification = this.defalutData,
    duration: number = 3000
  ): MatSnackBarRef<any> {
    const snackBar = this._snackBar.openFromComponent(component, {
      data,
      duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
    return snackBar;
  }
}
