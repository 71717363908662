import { NgModule } from "@angular/core";
import { NdrReattemptDetailsComponent } from "./component/ndr-reattempt-details.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [NdrReattemptDetailsComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class NdrReattemptDetailsModule {}
