import { NgModule } from "@angular/core";
import { TrackSingleOrderComponent } from "./component/track-single-order.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { TransitDaysModule } from "../../pipes/transit-days/transit-days.module";
import { PipesModule } from "../../pipes/pipes.module";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    declarations: [TrackSingleOrderComponent],
    imports: [
        // fuse
        FuseSharedModule,

        // shared
        ErrorMessageModule,

        // pipes
        PipesModule,
        TransitDaysModule,

        // material
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
})
export class TrackSingleOrderModule {}
