import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import * as Sentry from "@sentry/angular";
import { CouriersService } from "./couriers.service";
import { FetchUsersService } from "./fetch-users.service";
import { ProfileDataService } from "./profile-data.services";
import { ToolbarNotificationService } from "app/main/ui/toolbar-notification.service";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  private jwtHelper = new JwtHelperService();
  //   confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
    private _router: Router,
    private _splashScreen: FuseSplashScreenService,
    private _profileDataService: ProfileDataService,
    private _fetchUsersService: FetchUsersService,
    private _couriersService: CouriersService,
    private _dialog: MatDialog,
    private _toolbarNotification: ToolbarNotificationService
  ) {}

  setToken(token: string): void {
    localStorage.setItem("shippiGoToken", token);
  }

  getToken(): string {
    const token = localStorage.getItem("shippiGoToken");
    return token || "";
  }

  clearToken(): void {
    localStorage.removeItem("shippiGoToken");
  }

  logout(): void {
    this.clearToken();
    this._router.navigate(["login"]);
    this._splashScreen.hide();

    // close all opened modals
    this._dialog.closeAll();

    setTimeout(() => {
      this.clearObservables();
    }, 500);

    // clearing user fingerprint generated for sentry
    this.clearSentryScope();
  }

  clearObservables(): void {
    // clearing profile
    this._profileDataService.profileData.next({});

    // clearing user permissions and authorised routes
    this._profileDataService._authorisedRoutes.next([]);
    this._profileDataService._userPermissions.next({});

    //clearing all users list
    this._fetchUsersService.userList.next([]);

    //clearing couriers list
    this._couriersService.couriers.next([]);

    //clearing tollbar notifications

    this._toolbarNotification.clear();
  }

  getUser(): any {
    const user = this.jwtHelper.decodeToken(this.getToken());
    return user || {};
  }

  isMainAdmin(): boolean {
    const { adminType } = this.getUser();
    return adminType === "main_admin";
  }

  haveWriteAccess(): boolean {
    const { noWriteAccess } = this.getUser();
    return !noWriteAccess;
  }

  clearSentryScope(): void {
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  isUserLoggedIn(): boolean {
    const token = this.getToken();
    return !!token;
  }
}
