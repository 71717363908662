import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReuseableBoxDetailsTableComponent } from "./reuseable-box-details-table/reuseable-box-details-table.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { BoxDetailsTableModule } from "../box-details-table/box-details-table.module";

@NgModule({
  declarations: [ReuseableBoxDetailsTableComponent],
  imports: [
    CommonModule,
    BoxDetailsTableModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FuseSharedModule
  ]
})
export class ReuseableBoxDetailsTableModule {}
