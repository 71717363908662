import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PriceBreakupComponent } from "./price-breakup/price-breakup.component";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { FuseSharedModule } from "@fuse/shared.module";
import { PipesModule } from "../../pipes/pipes.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [PriceBreakupComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    FuseSharedModule,
    PipesModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule
  ]
})
export class PriceBreakupModule {}
