import { NgModule } from "@angular/core";
import { GeneratePickupResponseComponent } from "./component/generate-pickup-response.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";

@NgModule({
  declarations: [GeneratePickupResponseComponent],
  imports: [
    // fuse
    FuseSharedModule,

    // material
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [GeneratePickupResponseComponent]
})
export class GeneratePickupResponseModule {}
