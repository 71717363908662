import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DigitsDirective } from "./digits.directive";

@NgModule({
  declarations: [DigitsDirective],
  imports: [CommonModule],
  exports: [DigitsDirective]
})
export class DigitsModule {}
