import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProfileDataService {
  profileData: BehaviorSubject<any> = new BehaviorSubject({});

  _userPermissions: BehaviorSubject<any> = new BehaviorSubject({});
  userPermissions = this._userPermissions.asObservable();

  _authorisedRoutes: BehaviorSubject<any> = new BehaviorSubject([]);
  authorisedRoutes = this._authorisedRoutes.asObservable();

  constructor(private _http: HttpClient) {}

  getProfileData(): void {
    this._http.get("admin/other/profile").subscribe(
      (res: any) => {
        if (res.success) {
          // console.log("res", res);
          this.profileData.next(res.profile);
          this._userPermissions.next(res.permissions);
        } else {
          this.profileData.next({});
          this._userPermissions.next({});
        }
      },
      () => {
        this.profileData.next({});
        this._userPermissions.next({});
      }
    );
  }
}
