import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { LoginService } from "app/main/shared/services/login.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.services";
import * as Sentry from "@sentry/angular";
import { MatDialog } from "@angular/material/dialog";
import { BulkProcessComponent } from "app/main/shared/modals/bulk-process/component/bulk-process.component";
import { TenantService } from "app/main/shared/services/tenant.service";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  tenants: any[];
  currentTenant: string = this._loginService.getUser()?.tenant;
  haveAccessTenant: boolean =
    !this._loginService.getUser()?.forceTenant || false;

  profile: any = {
    firstName: "",
    lastName: ""
  };

  lightTheme: boolean;
  isUserLoggedIn: boolean = this._loginService.isUserLoggedIn();

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _loginService: LoginService,
    private _profileDataService: ProfileDataService,
    private _dialog: MatDialog,
    private _tenantService: TenantService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50"
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107"
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336"
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD"
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161"
      }
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us"
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr"
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Check and set user theme
    this.checkUserTheme();

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang
    });

    const userLoggedIn = !!this._loginService.getToken();

    if (userLoggedIn) {
      this.patchAvatarName();
      this.fetchProfileData();

      //Fetch Tenants
      if (this.haveAccessTenant) this.fetchTenants();
    }
  }

  fetchProfileData(): void {
    this._profileDataService.getProfileData();

    this._profileDataService.profileData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profileObj) => {
        if (profileObj?.weightDescProcess === "open")
          this.openProcessing(profileObj, "wdProcessingModal");

        if (profileObj?.codRemitProcess === "open")
          this.openProcessing(profileObj, "codProcessingModal");
      });
  }

  openProcessing(profileData, modalId) {
    if (!this._dialog.getDialogById(modalId)) {
      this._dialog.open(BulkProcessComponent, {
        panelClass: "medium-modal",
        id: modalId,
        data: { ...profileData, modalId }
      });
    }
  }

  patchAvatarName(): void {
    let { name, email } = this._loginService.getUser();

    // console.log(this._loginService.getUser());

    name = name.split(" ");
    let firstName = name[0];
    let lastName = name[1];

    if (firstName && lastName) {
      Object.assign(this.profile, { firstName, lastName });
    }

    this.captureUser(email);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this._loginService.logout();
  }

  // Capturing the User for sentry errors
  captureUser(email: string) {
    Sentry.configureScope((scope) => {
      scope.setUser({ email });
    });
  }

  checkUserTheme(): void {
    const theme = localStorage.getItem("colorTheme");
    theme ? this.setTheme(theme) : this.setTheme("theme-default");
  }

  setTheme(theme: string): void {
    localStorage.setItem("colorTheme", theme);
    this._fuseConfigService.config = { colorTheme: theme };

    if (theme === "theme-default") {
      this.lightTheme = true;
    } else this.lightTheme = false;
  }

  switchToOtherTenant(tenant) {
    const token = this._loginService.getToken();

    this._tenantService.switchTenant({ token, tenant: tenant }, (token) => {
      this._loginService.setToken(token);
      window.location.reload();
    });
  }

  fetchTenants(): void {
    let apiCalled = false;

    this._tenantService.tenants
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tenants) => {
        if (tenants.length || apiCalled) {
          this.tenants = tenants;
        } else if (!apiCalled) {
          apiCalled = true;
          this._tenantService.getTenants();
        }
      });
  }
}
