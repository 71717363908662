<div fxLayout="row" fxLayoutAlign="center center" class="background-color">
    <!-- // blank div for alignment fix -->
    <!-- <div></div> -->
    <!-- <div fxLayout="row" fxLayoutAlign="center center"> -->
    <p class="my-4 py-4 text-black">
      It looks like you are using an unsupported browser. Please use a
      <a (click)="openSupportedBrowsersModal()" class="cursor-pointer"
        >Supported Browser</a
      >  
      for better user experience.
    </p>
    <!-- </div> -->
    <!-- <button mat-icon-button color="primary" (click)="closeBanner()">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>
  