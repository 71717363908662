import { NgModule } from "@angular/core";
import { PickupDetailsComponent } from "./component/pickup-details.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [PickupDetailsComponent],
    imports: [
        // fuse
        FuseSharedModule,

        // material
        MatToolbarModule,
        MatButtonModule, 
        MatIconModule,
        MatDialogModule,
    ],
})
export class PickupDetailsModule {}
