import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseNavigationModule } from "@fuse/components";
import { PipesModule } from "app/main/shared/pipes/pipes.module";
import { AcceptDiscrepancyComponent } from "./accept-discrepancy.component";

@NgModule({
  declarations: [AcceptDiscrepancyComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    FuseNavigationModule,
    FuseSharedModule,
    MatDialogModule,
    PipesModule
  ]
})
export class AcceptDiscrepancyModule {}
