import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { LoginService } from "../services/login.service";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class LoginGuard implements CanActivate {
    constructor(private _loginService: LoginService, private _router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.guardLogic();
    }

    guardLogic(): Observable<boolean> | boolean {
        const userLoggedIn = this._loginService.getToken();

        if (!!userLoggedIn) {
            return of(false).pipe(
                tap(() => {
                    this._router.navigate(["orders"]);
                })
            );
        } else {
            return true;
        }
    }
}
