import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Tenant } from "../interfaces/tenant";
import { NotificationService } from "./notification.service";
import { userTenantConfig } from "app/main/shared/ts/userTenantConfig";

const tenantConfig = {
  shippigo: userTenantConfig.shippigoTenantConfig,
  emiza: userTenantConfig.emizaTenantConfig
};

@Injectable({
  providedIn: "root"
})
export class TenantService {
  tenants: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(
    private _http?: HttpClient,
    private _notification?: NotificationService
  ) {}

  switchTenant(
    { tenant, token }: { tenant: string; token: string },
    passAction: (token: string) => void = () => {},
    failAction: () => void = () => {}
  ) {
    const body = { tenant };
    const headers = {
      "x-access-token": token
    };

    this._http.post("admin/other/changeTenant", body, { headers }).subscribe(
      (res: any) => {
        if (res.success) {
          passAction(res.token);
        } else {
          if (failAction) failAction();
          this._notification.show(res.msg);
        }
      },
      (err) => {
        if (failAction) failAction();
        throw err;
      }
    );
  }

  getTenants() {
    this._http.get("admin/other/getTenants").subscribe(
      (res: any) => {
        if (res.success) {
          this.tenants.next(res.dashboard);
        } else {
          this.tenants.next([]);
          this._notification.show(res.msg);
        }
      },
      (err) => {
        this.tenants.next([]);
        throw err;
      }
    );
  }

  getTenantConfig(tenantName: string): Tenant {
    return tenantConfig[tenantName];
  }
}
