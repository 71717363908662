import { HttpClient } from "@angular/common/http";
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RequestService } from "app/main/request/request.service";
import { LoginService } from "app/main/shared/services/login.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { TransactionsApiService } from "app/main/transactions/transactions-api.service";
import { Subscription, timer } from "rxjs";
import { delay, retryWhen, share, switchMap, take } from "rxjs/operators";

@Component({
  selector: "app-bulk-process",
  templateUrl: "./bulk-process.component.html",
  styleUrls: [
    "./bulk-process.component.scss",
    "./../../../scss/responsive-modals.scss"
  ],
  encapsulation: ViewEncapsulation.None
})
export class BulkProcessComponent implements OnInit, OnDestroy {
  private checkBulkSub: Subscription;
  bulkProcessStatus: string = "processing";
  title: string;
  response: any;
  totalOrder = 10;
  processedOrder = 1;
  progressValue = 1;
  closeIcon: string = "minimize";
  success: boolean;

  constructor(
    private _http: HttpClient,
    private _loginService: LoginService,
    private _transactionsApiService: TransactionsApiService,
    private _requestService: RequestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<BulkProcessComponent>,
    private _notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.getProcessingStatus();
  }

  getProcessingStatus(): void {
    const { data } = this;
    const tenSeconds = 10 * 1000;
    let processId: any;
    let url: string;

    if (
      data?.modalId === "wdProcessingModal" ||
      data?.title?.trim().toLowerCase() === "weight discrepancy"
    ) {
      processId = data?.weightDescTaskId || data?.weightDescData?.lastWDtaskId;
      url = "admin/order/checkWDstatus";
      this.title = "Weight Discrepancy";
    }

    if (
      data?.modalId === "codProcessingModal" ||
      data?.title?.trim().toLowerCase() === "cod remittance to user" ||
      data?.title?.trim().toLowerCase() === "cod remittance to sg"
    ) {
      processId = data?.codRemitTaskId || data?.codRemitData?.lastCodTaskId;
      url = "admin/order/checkCODremitStatus";
      this.title = "COD Remittance";
    }
    const body = { processId };

    this.checkBulkSub = timer(1, tenSeconds)
      .pipe(
        switchMap(() => this._http.post(url, body)),
        retryWhen((errors) => errors.pipe(delay(tenSeconds * 3), take(3))),
        share()
      )
      .subscribe(
        (res: any) => {
          if (res.success) {
            let { data } = res;
            if (data?.msg !== "done") {
              this.processedOrder = data?.processedAwb;
              this.totalOrder = data?.totalAwb;
              this.progressValue = (data?.processedAwb / data?.totalAwb) * 100;
            } else {
              this.closeIcon = "close";
              this.bulkProcessStatus = "processed";
              this.response = data;
              this.stopCallingAPI();
              if (this.title === "COD Remittance") {
                this._transactionsApiService.refreshRemitDetails();
              } else if (this.title === "Weight Discrepancy")
                this._requestService.refreshOrders();
            }
            this.success = res.success;
          } else {
            this.stopCallingAPI();
            this.bulkProcessStatus = "processed";
            this.closeIcon = "close";
            this.success = res.success;
            this.response = res.msg;
          }
        },
        (err) => {
          this.stopCallingAPI();
          this._loginService.logout();
        }
      );
  }

  ngOnDestroy(): void {
    this.stopCallingAPI();
  }

  stopCallingAPI(): void {
    this.checkBulkSub.unsubscribe();
  }

  closeDialog(): void {
    this._dialogRef.close();

    if (this.bulkProcessStatus === "processing")
      this._notification.show(
        "You have minimised the processing modal. You can open it from the notifications tab.",
        "Close",
        5000
      );
  }
}
