import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class CouriersService {
  // declarations
  couriers: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  getCouriers(): void {
    this._http.get("admin/other/getCourier").subscribe(
      (res: any) => {
        if (res.success) {
          this.couriers.next(this.sortCouriers(res.couriers));
        } else {
          this.couriers.next([]);
          this._notification.show(res.msg);
        }
      },
      (error) => {
        this.couriers.next([]);
        throw error;
      }
    );
  }

  sortCouriers(couriers): any {
    const sortedCouriers = couriers.sort((a, b) => {
      const A = a.courierPartner?.toUpperCase();
      const B = b.courierPartner?.toUpperCase();

      if (A > B) {
        return 1;
      } else if (A < B) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedCouriers;
  }
}
