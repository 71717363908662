import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectUserComponent } from "./select-user.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { StringFilterByModule } from "../../pipes/string-filter-by/string-filter-by.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ErrorMessageModule } from "../error-message/error-message.module";

@NgModule({
  declarations: [SelectUserComponent],
  imports: [
    NgxMatSelectSearchModule,
    CommonModule,
    MatInputModule,
    MatSelectModule,
    StringFilterByModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorMessageModule
  ],
  exports: [SelectUserComponent]
})
export class SelectUserModule {}
