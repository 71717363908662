import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";
import { NotificationService } from "../services/notification.service";
import { ProfileDataService } from "../services/profile-data.services";
import { map, skipWhile, take, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DynamicModulePermissionGuard
  implements CanActivate, CanActivateChild
{
  constructor(
    private _profileDataService: ProfileDataService,
    private _loginService: LoginService,
    private _notification: NotificationService,
    private _router: Router
  ) {}

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.commonGuardLogic(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.commonGuardLogic(state.url);
  }

  commonGuardLogic(url: string): Observable<boolean> | boolean {
    console.log(`current url is: ${url}`);

    if (this._loginService.isMainAdmin()) {
      return true;
    }

    return this._profileDataService.authorisedRoutes.pipe(
      skipWhile((authorisedUrls) => !authorisedUrls.length),
      take(1),
      map((authorisedUrls) => {
        return authorisedUrls.includes(url);
      }),
      tap((val) => {
        if (!val) {
          this.navigateToHome();
        }
      })
    );
  }

  navigateToHome(): void {
    this._notification.show("You are not authorised to access this module.");
    this._router.navigateByUrl("/");
  }
}
