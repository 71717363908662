import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root"
})
export class SidebarNavigationService {
  moduleDataCount: BehaviorSubject<any> = new BehaviorSubject({});
  trackingDataCount: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService
  ) {}

  refreshCounts() {
    this.getCounts();
  }
  refreshTrackingCounts() {
    this.getTrackingCounts();
  }

  getCounts(): void {
    this._http.get("admin/customer/countStats").subscribe(
      (res: any) => {
        if (res.success) {
          this.moduleDataCount.next(res.count);
        } else {
          this.moduleDataCount.next({});
        }
      },
      () => {
        this.moduleDataCount.next({});
      }
    );
  }

  getTrackingCounts(): void {
    this._http.get("admin/deliveryManager/reqfeedstats").subscribe(
      (res: any) => {
        if (res.success) {
          this.trackingDataCount.next(res?.count);
        } else {
          this.trackingDataCount.next({});
        }
      },
      () => {
        this.trackingDataCount.next({});
      }
    );
  }

  viewFeedbackRequest(body) {
    this._http
      .post("admin/deliveryManager/statsviewupdate", body)
      .subscribe((res: any) => {
        if (res.success) {
          this.refreshTrackingCounts();
        } else {
          this._notification.show(res?.msg || res?.message);
        }
      });
  }
}
