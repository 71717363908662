import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageInputComponent } from "./component/image-input.component";
import { UploadImageComponent } from "./modal/upload-image/upload-image.component";
import { AddImageComponent } from "./modal/upload-image/child-components/add-image/add-image.component";
import { EditImageComponent } from "./modal/upload-image/child-components/edit-image/edit-image.component";
import { PreviewImageComponent } from "./modal/upload-image/child-components/preview-image/preview-image.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { ImageCropperModule } from "ngx-image-cropper";
import { FileSizeModule } from "../../pipes/file-size/file-size.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { UploadImageApiService } from "./upload-image-api.service";
import { DragAndDropModule } from "../../directives/drag-and-drop/drag-and-drop.module";

@NgModule({
  declarations: [
    ImageInputComponent,
    UploadImageComponent,
    AddImageComponent,
    EditImageComponent,
    PreviewImageComponent
  ],
  imports: [
    FuseSharedModule,
    ImageCropperModule,
    // pipe
    FileSizeModule,
    // directive
    DragAndDropModule,

    // material module
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [ImageInputComponent],
  providers: [UploadImageApiService]
})
export class ImageInputModule {}
