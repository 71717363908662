import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FuseConfirmDialogModule } from "@fuse/components";
import { SingleOrderDetailsModule } from "./modals/single-order-details/single-order-details.module";
import { TrackSingleOrderModule } from "./modals/track-single-order/track-single-order.module";
import { UpdateTrackingModule } from "./modals/update-tracking/update-tracking.module";
import { PickupDetailsModule } from "./modals/pickup-details/pickup-details.module";
import { SmsLogsModule } from "./modals/sms-logs/sms-logs.module";
import { RequestFeedbackModule } from "./modals/request-feedback/request-feedback.module";
import { UserDetailsModule } from "./modals/user-details/user-details.module";
import { SubAdminPermissionsModule } from "./modals/sub-admin-permissions/sub-admin-permissions.module";
import { UpdateOrderDataModule } from "./modals/update-order-data/update-order-data.module";
import { AutoDeductDateModule } from "./modals/auto-deduct-date/auto-deduct-date.module";
import { ActionWeightDiscModule } from "./modals/action-weight-disc/action-weight-disc.module";
import { CancelOrderResponseModule } from "./modals/cancel-order-response/cancel-order-response.module";
import { GeneratePickupResponseModule } from "./modals/generate-pickup-response/generate-pickup-response.module";
import { GenerateMediaModule } from "./modals/generate-media/generate-media.module";
import { CloseOrderModule } from "./modals/close-order/close-order.module";
import { SupportedBrowserListModule } from "./modals/supported-browser-list/supported-browser-list.module";
import { CheckServiceabilityModule } from "./modals/check-serviceability/check-serviceability.module";
import { NdrReattemptDetailsModule } from "./modals/ndr-reattempt-details/ndr-reattempt-details.module";
import { BulkProcessModule } from "./modals/bulk-process/bulk-process.module";
import { VirtualScrollSelectModule } from "./modules/virtual-scroll-select/virtual-scroll-select.module";
import { DateRangeModule } from "./modals/date-range/date-range.module";
import { TrackingRemarkModule } from "./modals/tracking-remark/tracking-remark.module";
import { NdrLogModule } from "./modals/ndr-log/ndr-log.module";
import { NotificationLogModule } from "./modals/notification-log/notification-log.module";
import { ConfirmRtoRequestModule } from "./modals/confirm-rto-request/confirm-rto-request.module";
import { NdrReattemptDeliveryModule } from "./modals/ndr-reattempt-delivery/ndr-reattempt-delivery.module";
import { ViewImageModalModule } from "./modals/view-image-modal/view-image-modal.module";
import { DisputeDiscrepancyModule } from "./modals/dispute-discrepancy-modal/dispute-discrepancy.module";
import { AcceptDiscrepancyModule } from "./modals/accept-discrepancy/accept-discrepancy.module";
import { DownloadWeightDiscrepancyOrdersModule } from "./modals/download-weight-discrepancy-orders/download-weight-discrepancy-orders.module";
import { SelectUserModule } from "./modules/select-user/select-user.module";
import { ItemsModalForWdModule } from "./modals/items-modal-for-wd/items-modal-for-wd.module";
import { DownloadNdrOrdersModule } from "./modals/download-ndr-orders/download-ndr-orders.module";
import { BulkProcessForImportOrdersModule } from "./modals/bulk-process-for-import-orders/bulk-process-for-import-orders.module";
import { ReportsSubscribersModule } from "./modals/reports-subscribers/reports-subscribers.module";
import { AwbsCODRechargeModule } from "./modals/awbs-cod-recharge/awbs-cod-recharge.module";
import { ReuseableBoxDetailsTableModule } from "./modals/reuseable-box-details-table/reuseable-box-details-table.module";
import { BoxDetailsTableModule } from "./modals/box-details-table/box-details-table.module";
import { PriceBreakupModule } from "./modals/price-breakup/price-breakup.module";
import { OrderHistoryModule } from "../request/modals/order-history/order-history.module";
import { ConfirmationModalForRequestsModule } from "./modals/confirmation-modal-for-requests/confirmation-modal-for-requests.module";
import { ConfirmUpdateRequestModule } from "./modals/confirm-update-request/confirm-update-request.module";
import { ImageInputModule } from "./modules/image-input/image-input.module";
import { ActuatorHealthComponent } from './components/actuator-health/actuator-health.component';

@NgModule({
  declarations: [
    ActuatorHealthComponent
  ],
  imports: [
    CommonModule,
    //fuse
    FuseConfirmDialogModule,
    SingleOrderDetailsModule,
    TrackSingleOrderModule,
    UpdateTrackingModule,
    PickupDetailsModule,
    SmsLogsModule,
    RequestFeedbackModule,
    UserDetailsModule,
    SubAdminPermissionsModule,
    UpdateOrderDataModule,
    AutoDeductDateModule,
    ActionWeightDiscModule,
    CancelOrderResponseModule,
    GeneratePickupResponseModule,
    GenerateMediaModule,
    CloseOrderModule,
    SupportedBrowserListModule,
    CheckServiceabilityModule,
    NdrReattemptDetailsModule,
    BulkProcessModule,
    VirtualScrollSelectModule,
    DateRangeModule,
    TrackingRemarkModule,
    NdrLogModule,
    NotificationLogModule,
    ConfirmRtoRequestModule,
    NdrReattemptDeliveryModule,
    ViewImageModalModule,
    DisputeDiscrepancyModule,
    AcceptDiscrepancyModule,
    DownloadWeightDiscrepancyOrdersModule,
    SelectUserModule,
    ItemsModalForWdModule,
    DownloadNdrOrdersModule,
    BulkProcessForImportOrdersModule,
    ReportsSubscribersModule,
    AwbsCODRechargeModule,
    OrderHistoryModule,
    ConfirmationModalForRequestsModule,
    ReuseableBoxDetailsTableModule,
    BoxDetailsTableModule,
    PriceBreakupModule,
    ConfirmUpdateRequestModule,
    AwbsCODRechargeModule,
    ImageInputModule,
    ReuseableBoxDetailsTableModule,
    BoxDetailsTableModule,
    PriceBreakupModule,
    ConfirmUpdateRequestModule
  ],
  exports: []
})
export class SharedModule {}
