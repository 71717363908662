import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [OrderHistoryComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FuseSharedModule,
    MatDialogModule
  ]
})
export class OrderHistoryModule {}
