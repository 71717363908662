import { Tenant } from "../interfaces/tenant";

const shippigoTenantConfig: Tenant = {
  name: "shippigo",
  sName: "Shippigo",
  title: "Shippigo - eCommerce",
  tagline: "Welcome to Shippigo!",
  companyName: "Saged Retail Solutions Private Limited",
  origin: "https://app.shippigo.com",
  phone: "9986724291",
  email: "contact@shippigo.com",
  termsPageUrl: "https://www.shippigo.com/terms",
  privacyPageUrl: "https://www.shippigo.com/privacy",
  cancelationAndRefundUrl:
    "https://shippigo.com/cancellation-and-refund-policy",
  landingPageUrl: "https://www.shippigo.com",
  contactPageUrl: "https://shippigo.com/#contact",
  bankingDetails: {
    accountHolderName: "Saged Retail Solutions Private Limited",
    bankName: "HDFC",
    accountNumber: "50200034652151",
    ifscCode: "HDFC0003648"
  },
  lightLogos: {
    main: "https://app.shippigo.com/assets/images/logos/shippigo-by-emiza-white.svg",
    square: "assets/images/logos/shippigo-white.svg"
  },
  darkLogos: {
    main: "https://app.shippigo.com/assets/images/logos/shippigo-by-emiza.svg",
    square: "assets/images/logos/shippigo.svg"
  },
  poweredByLogoUrl:
    "https://app.shippigo.com/assets/images/logos/powered_by_shippigo.png",
  faviconIconUrl: "assets/images/logos/shippigo-favicon.png",
  loaderUrl: "assets/images/logos/loader.gif",
  deliveryManagerUrl: "https://tracking-page-dev.shippigo.com/delivery-manager",
  deliveryManagerProdUrl: "https://tracking-page.shippigo.com/delivery-manager",
  socialMedia: {
    fb: "https://www.facebook.com/shippigoofficial",
    twitter: "https://twitter.com/shippigo",
    insta: "https://www.instagram.com/shippigo_official",
    whatsapp: ""
  }
};

const emizaTenantConfig: Tenant = {
  name: "emiza",
  sName: "Emiza",
  title: "Ship with Emiza",
  tagline: "",
  companyName: "Emiza Supply Chain Services Private Limited",
  origin: "https://ship.emizainc.com",
  phone: "9986724291",
  email: "contact@emizainc.com",
  landingPageUrl: "https://emizainc.com",
  termsPageUrl: "https://emizainc.com/terms-and-conditions",
  privacyPageUrl: "https://emizainc.com/privacy-policy",
  cancelationAndRefundUrl: "https://emizainc.com/refund-cancellation-policy",
  contactPageUrl: "https://emizainc.com/lets-talk",
  bankingDetails: {
    accountHolderName: "Emiza Supply Chain Services Private Limited",
    bankName: "Axis Bank",
    accountNumber: "922020049919634",
    ifscCode: "UTIB0000020"
  },
  lightLogos: {
    main: "https://ship.emizainc.com/assets/images/tenant/emiza/white-logo.png",
    square: "assets/images/tenant/emiza/square-white-logo.png"
  },
  darkLogos: {
    main: "https://ship.emizainc.com/assets/images/tenant/emiza/logo.png",
    square: "assets/images/tenant/emiza/square-logo.png"
  },
  poweredByLogoUrl:
    "https://ship.emizainc.com/assets/images/tenant/emiza/powered-by-emiza.png",
  faviconIconUrl: "assets/images/tenant/emiza/favicon.png",
  loaderUrl: "assets/images/tenant/emiza/loader.svg",
  deliveryManagerUrl: "https://tracking-page-dev.emizainc.com/delivery-manager",
  deliveryManagerProdUrl: "https://tracking-page.emizainc.com/delivery-manager",
  socialMedia: {
    fb: "https://www.facebook.com/emizainc",
    twitter: "https://twitter.com/Emiza_Inc",
    insta: "https://www.instagram.com/emizainc",
    whatsapp: ""
  }
};

export const userTenantConfig = { shippigoTenantConfig, emizaTenantConfig };
