import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BulkProcessComponent } from "./component/bulk-process.component";
import { ProcessedComponent } from "./child-components/processed/processed.component";
import { ProcessingComponent } from "./child-components/processing/processing.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { ProgressStatusDirective } from "./progress-status.directive";

@NgModule({
  declarations: [
    BulkProcessComponent,
    ProcessedComponent,
    ProcessingComponent,
    ProgressStatusDirective
  ],
  imports: [
    FuseSharedModule,

    // material
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class BulkProcessModule {}
