export const navigationConfig = {
  dashboardPermissions: {
    id: "dashboardPermissions",
    title: "Dashboard",
    type: "group",
    children: []
  },

  overview: {
    id: "overview",
    title: "Overview",
    type: "item",
    url: "/dashboard/overview",
    icon: "grid_on",
    parentId: "dashboardPermissions"
  },

  liveness: {
    id: "liveness",
    title: "Liveness Probe",
    type: "item",
    url: "/dashboard/liveness",
    icon: "av_timer",
    parentId: "dashboardPermissions"
  },

  pickup: {
    id: "pickup",
    title: "Pickups",
    type: "item",
    url: "/dashboard/pickup",
    icon: "local_shipping",
    parentId: "dashboardPermissions"
  },

  serviceabilityCount: {
    id: "serviceabilityCount",
    title: "Serviceability Count",
    type: "item",
    url: "/dashboard/serviceability-count",
    icon: "av_timer",
    parentId: "dashboardPermissions"
  },

  bulk: {
    id: "bulk",
    title: "Bulk Status",
    type: "item",
    url: "/dashboard/bulk-status",
    icon: "donut_large",
    parentId: "dashboardPermissions"
  },

  trackingMetrics: {
    id: "trackingMetrics",
    title: "Tracking Metrics",
    type: "item",
    url: "/dashboard/tracking-metrics",
    icon: "art_track",
    parentId: "dashboardPermissions"
  },

  cronMetrics: {
    id: "cronMetrics",
    title: "Cron Metrics",
    type: "item",
    url: "/dashboard/cron-metrics",
    icon: "art_track",
    parentId: "dashboardPermissions"
  },

  actionsPermissions: {
    id: "actionsPermissions",
    title: "Actions",
    type: "group",
    children: []
  },

  addOrder: {
    id: "addOrder",
    title: "Add Order",
    type: "item",
    url: "/actions/add-order",
    icon: "add",
    parentId: "actionsPermissions"
  },

  importOrder: {
    id: "importOrder",
    title: "Import Order",
    type: "item",
    url: "/actions/import-order",
    icon: "add_to_queue",
    parentId: "actionsPermissions"
  },

  broadcast: {
    id: "broadcast",
    title: "Broadcast",
    type: "item",
    url: "/actions/broadcast",
    icon: "broadcast",
    parentId: "actionsPermissions"
  },

  pincode: {
    id: "pincode",
    title: "Pincode Update",
    type: "item",
    url: "/actions/pincode-history",
    icon: "update",
    parentId: "actionsPermissions"
  },
  tatUpdate: {
    id: "tatUpdate",
    title: "TAT Update",
    type: "item",
    url: "/actions/update-tat",
    icon: "update",
    parentId: "actionsPermissions"
  },

  downloadCsv: {
    id: "downloadCsv",
    title: "Download CSV",
    type: "item",
    icon: "cloud_download",
    url: "/actions/download-csv",
    parentId: "actionsPermissions"
  },

  revenueReport: {
    id: "revenueReport",
    title: "Revenue Report",
    type: "item",
    icon: "timeline",
    url: "/actions/revenue-report",
    parentId: "actionsPermissions"
  },

  rateCalculator: {
    id: "rateCalculator",
    title: "Rate Calculator",
    type: "item",
    url: "/actions/rate-calculator",
    svgIcon: "calculator-alt",
    parentId: "actionsPermissions"
  },

  configureVAS: {
    id: "configureVAS",
    title: "Configure VAS",
    type: "collapsable",
    icon: "devices",
    parentId: "actionsPermissions",
    children: []
  },

  configureCustomTracking: {
    id: "configureCustomTracking",
    title: "Custom Tracking",
    type: "item",
    icon: "web",
    url: "/actions/configure-vas/custom-tracking",
    parentId: "configureVAS"
  },

  billingInvoices: {
    id: "billingInvoices",
    title: "Courier Billing & Invoices",
    type: "item",
    url: "/actions/billing-invoices",
    svgIcon: "calculator-alt",
    parentId: "actionsPermissions"
  },

  skuMaster: {
    id: "skuMaster",
    title: "SKU Master",
    type: "item",
    url: "/actions/sku-master",
    icon: "table_chart",
    parentId: "actionsPermissions"
  },

  generateReports: {
    id: "generateReports",
    title: "Generate Reports",
    type: "item",
    icon: "description",
    url: "/actions/generate-reports",
    parentId: "downloadPermissions"
  },

  financeReport: {
    id: "financeReport",
    title: "Financial Reports",
    type: "item",
    icon: "description",
    url: "/actions/finance-reports",
    parentId: "downloadPermissions"
  },

  reports: {
    id: "reports",
    title: "Shipment Reports",
    type: "item",
    icon: "description",
    url: "/actions/reports",
    parentId: "downloadPermissions"
  },

  rateGenerator: {
    id: "rateGenerator",
    title: "Rate Generator",
    type: "item",
    icon: "list",
    url: "/actions/rate-generator",
    parentId: "downloadPermissions"
  },

  ordersManagementPermissions: {
    id: "ordersManagementPermissions",
    title: "Orders Management",
    type: "group",
    children: []
  },

  allorders: {
    id: "allorders",
    title: "All Orders",
    type: "item",
    icon: "format_list_bulleted",
    url: "/orders/allorders",
    parentId: "ordersManagementPermissions"
  },

  cod: {
    id: "cod",
    title: "COD Orders",
    type: "item",
    icon: "attach_money",
    url: "/orders/cod",
    parentId: "ordersManagementPermissions"
  },

  prepaid: {
    id: "prepaid",
    title: "Prepaid Orders",
    type: "item",
    icon: "credit_card",
    url: "/orders/prepaid",
    parentId: "ordersManagementPermissions"
  },

  // priority: {
  //   id: "priority",
  //   title: "Priority Orders",
  //   type: "item",
  //   icon: "flash_on",
  //   url: "/orders/priority",
  //   parentId: "ordersManagementPermissions"
  // },

  nonMoving: {
    id: "nonMoving",
    title: "Non Moving Orders",
    type: "item",
    icon: "block",
    url: "/orders/non-moving",
    parentId: "ordersManagementPermissions"
  },

  delivered: {
    id: "delivered",
    title: "Delivered Orders",
    type: "item",
    icon: "check_box",
    url: "/orders/delivered",
    parentId: "ordersManagementPermissions"
  },

  rto: {
    id: "rto",
    title: "RTO Orders",
    type: "item",
    icon: "reply_all",
    url: "/orders/rto",
    parentId: "ordersManagementPermissions"
  },

  flagged: {
    id: "flagged",
    title: "Flagged Orders",
    type: "item",
    icon: "flag",
    url: "/orders/flagged",
    parentId: "ordersManagementPermissions"
  },

  ndr: {
    id: "ndr",
    title: "NDR Orders (Beta)",
    type: "item",
    icon: "report_problem",
    parentId: "ordersManagementPermissions",
    url: "/orders/ndr"
  },

  // flaggedOpen: {
  //   id: "flaggedOpen",
  //   title: "Pending",
  //   type: "item",
  //   icon: "sync_problem",
  //   url: "/orders/ndr/open",
  //   parentId: "ndr"
  // },

  // flaggedClosed: {
  //   id: "flaggedClosed",
  //   title: "Closed",
  //   type: "item",
  //   icon: "playlist_add_check",
  //   url: "/orders/ndr/closed",
  //   parentId: "ndr"
  // },

  customer: {
    id: "customer",
    title: "Customer Response",
    type: "collapsable",
    icon: "record_voice_over",
    parentId: "ordersManagementPermissions",
    children: []
  },

  request: {
    id: "request",
    title: "Request",
    type: "item",
    icon: "pan_tool",
    url: "/orders/customer-response/request",
    parentId: "customer"
  },

  feedback: {
    id: "feedback",
    title: "Feedback",
    type: "item",
    icon: "grade",
    url: "/orders/customer-response/feedback",
    parentId: "customer"
  },

  requestsPermissions: {
    id: "requestsPermissions",
    title: "Request",
    type: "group",
    children: []
  },

  reattempt: {
    id: "reattempt",
    title: "Re-Attempt",
    type: "item",
    url: "/request/re-attempt",
    icon: "redo",
    parentId: "requestsPermissions"
  },

  trackingFlags: {
    id: "trackingFlags",
    title: "Tracking Flags",
    type: "item",
    url: "/request/tracking-flags",
    icon: "outlined_flag",
    parentId: "requestsPermissions"
  },

  weightDiscrepancy: {
    id: "weightDiscrepancy",
    title: "Weight Discrepancy (Beta)",
    type: "item",
    url: "/request/weight-discrepancy",
    icon: "disc_full",
    parentId: "requestsPermissions"
  },

  vas: {
    id: "vas",
    title: "Value Added Services",
    type: "collapsable",
    icon: "devices",
    parentId: "requestsPermissions",
    children: []
  },

  customTracking: {
    id: "customTracking",
    title: "Custom Tracking Page",
    type: "item",
    url: "/request/vas/custom-tracking",
    icon: "web",
    parentId: "vas"
  },

  customSms: {
    id: "customSms",
    title: "Custom SMS",
    type: "item",
    url: "/request/vas/custom-sms",
    icon: "textsms",
    parentId: "vas"
  },

  customEmail: {
    id: "customEmail",
    title: "Custom Email",
    type: "item",
    url: "/request/vas/custom-emails",
    icon: "email",
    parentId: "vas"
  },

  codVerification: {
    id: "codVerification",
    title: "COD Verification",
    type: "item",
    url: "/request/vas/cod-verification",
    icon: "sms_failed",
    parentId: "vas"
  },

  usersManagementPermissions: {
    id: "usersManagementPermissions",
    title: "Users Management",
    type: "group",
    children: []
  },

  newUser: {
    id: "newUser",
    title: "New Users",
    type: "item",
    icon: "person_add",
    url: "/users/new",
    parentId: "usersManagementPermissions"
  },

  existingUser: {
    id: "existingUser",
    title: "Existing Users",
    type: "item",
    icon: "people",
    url: "/users/existing",
    parentId: "usersManagementPermissions"
  },

  disabledUser: {
    id: "disabledUser",
    title: "Disabled Users",
    type: "item",
    icon: "block",
    url: "/users/disabled",
    parentId: "usersManagementPermissions"
  },

  subAdminUser: {
    id: "subAdminUser",
    title: "Sub Admin Users",
    type: "item",
    icon: "admin_panel_settings",
    url: "/users/sub-admin",
    parentId: "usersManagementPermissions"
  },

  profileEditRequest: {
    id: "profileEditRequest",
    title: "Profile Edit Request",
    type: "item",
    icon: "email",
    url: "/users/profile-edit",
    parentId: "usersManagementPermissions"
  },

  accountUpdate: {
    id: "accountUpdate",
    title: "Account Edit Request",
    type: "item",
    icon: "perm_identity",
    url: "/users/account-edit",
    parentId: "usersManagementPermissions"
  },

  transactionsPermission: {
    id: "transactionsPermission",
    title: "Transactions",
    type: "group",
    children: []
  },

  summary: {
    id: "summary",
    title: "Transaction Summary",
    type: "item",
    icon: "format_list_numbered",
    url: "/transactions/summary",
    parentId: "transactionsPermission"
  },

  transactionsStatus: {
    id: "status",
    title: "Transaction Status",
    type: "item",
    icon: "format_list_numbered",
    url: "/transactions/status",
    parentId: "transactionsPermission"
  },
  walletDescription: {
    id: "status",
    title: "Wallet Description",
    type: "item",
    icon: "format_list_numbered",
    url: "/transactions/wallet-description",
    parentId: "transactionsPermission"
  },

  remittance: {
    id: "remittance",
    title: "COD Remittance",
    type: "item",
    icon: "payment",
    url: "/transactions/codremit",
    parentId: "transactionsPermission"
  },

  recharge: {
    id: "recharge",
    title: "Wallet Recharge -  Bank Transfer",
    type: "item",
    icon: "account_balance_wallet",
    url: "/transactions/recharge",
    parentId: "transactionsPermission"
  },

  rechargeCod: {
    id: "rechargeCod",
    title: "Wallet Recharge - Pending COD",
    type: "item",
    icon: "account_balance_wallet",
    url: "/transactions/recharge-cod",
    parentId: "transactionsPermission"
  },

  failedOnlineRecharges: {
    id: "failedOnlineRecharge",
    title: "Failed Online Recharge",
    type: "item",
    icon: "money_off",
    url: "/transactions/failed-online-recharge",
    parentId: "transactionsPermission"
  },

  settingsPermissions: {
    id: "settingsPermissions",
    title: "Settings",
    type: "group",
    children: []
  },

  profile: {
    id: "profile",
    title: "Profile",
    type: "item",
    icon: "perm_identity",
    url: "/settings/profile",
    parentId: "settingsPermissions"
  },

  emailSubscription: {
    id: "emailSubscription",
    title: "Email Subscription",
    type: "item",
    icon: "email",
    url: "/settings/email",
    parentId: "settingsPermissions"
  },

  othersPermissions: {
    id: "othersPermissions",
    title: "Others",
    type: "group",
    children: []
  }

  // escalationMatrix: {
  //   id: "escalationMatrix",
  //   title: "Escalation Matrix",
  //   type: "item",
  //   icon: "format_list_numbered",
  //   url: "/others/escalation-matrix",
  //   parentId: "othersPermissions"
  // }

  // rateManagementPermission: {
  //   id: "rateManagement",
  //   title: "Rate Management",
  //   type: "group",
  //   children: []
  // },

  // rateMaster: {
  //   id: "rateMaster",
  //   title: "Rate Master",
  //   type: "item",
  //   url: "/rate-management/rate-master",
  //   icon: "rate_review",
  //   parentId: "rateManagementPermission"
  // },

  // assignRates: {
  //   id: "assignRates",
  //   title: "Assign Rates",
  //   type: "item",
  //   url: "/rate-management/assign-rates",
  //   icon: "assignment_ind",
  //   parentId: "rateManagementPermission"
  // },

  // newRatePlan: {
  //   id: "newRatePlan",
  //   title: "Create/Update Plan",
  //   type: "item",
  //   url: "/rate-management/new-rate-plan",
  //   icon: "edit",
  //   parentId: "rateManagementPermission"
  // }
};
