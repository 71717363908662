import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UploadImageApiService {
  constructor(private _http: HttpClient) {}

  uploadImage(body) {
    const formData = new FormData();
    formData.append("image", body.image, body.fileName);

    return this._http.post("admin/other/image-upload", formData);
  }
}
