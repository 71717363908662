import { NgModule } from "@angular/core";
import { ActionWeightDiscComponent } from "./component/action-weight-disc.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ErrorMessageModule } from "../../modules/error-message/error-message.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { DecimalsModule } from "../../directives/decimals/decimals.module";

@NgModule({
  declarations: [ActionWeightDiscComponent],
  imports: [
    //fuse
    FuseSharedModule,

    //shared
    ErrorMessageModule,

    //directives
    DecimalsModule,

    //material
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class ActionWeightDiscModule {}
