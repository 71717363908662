<div fxLayout="column" class="my-12">
  <div>
    <div
      *ngIf="!!processedOrders; else unprocessed"
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <mat-icon class="green-fg status-icon">done</mat-icon>
      <h2 class="green-fg status-text">{{ title }} Successfully Uploaded.</h2>
    </div>

    <ng-template #unprocessed>
      <div fxLayout="column" fxLayoutAlign="start center">
        <mat-icon class="warn-fg status-icon">close</mat-icon>

        <h2 class="warn-fg status-text">{{ title }} Processing Failed!</h2>
      </div>
    </ng-template>
  </div>
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
      fxLayout.lt-sm="column"
    >
      <h3 *ngIf="success; else msg" class="mt-0 mb-20">
        Processed {{ processedOrders }} orders out of
        {{ response?.totalAwb || 0 }} orders
      </h3>
    </div>
    <ng-template #msg>
      <h3 class="warn-fg">Error: {{ response }}</h3>
    </ng-template>

    <div fxLayout="column" fxLayoutAlign="start start" fxFlex>
      <!-- for Weight Discrepancy Already Updated -->
      <div
        *ngIf="response?.awbAlreadyUploaded?.length"
        class="list"
        fxFlex="100%"
      >
        <h3 class="green-fg m-0 font-weight-600">
          Weight Discrepancy Already Updated (Total:{{
            response?.awbAlreadyUploaded?.length
          }})
        </h3>
        <p class="mt-8">
          <span *ngFor="let awb of response?.awbAlreadyUploaded; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <!-- for Weight Discrepancy -->
      <div *ngIf="response?.awbNotFound?.length" class="list" fxFlex="100%">
        <h3 class="red-fg m-0 font-weight-600">
          Weight Discrepancy Orders Not Found (Total:{{
            response?.awbNotFound?.length
          }})
        </h3>
        <p class="mt-8">
          <span *ngFor="let awb of response.awbNotFound; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <div *ngIf="response?.awbInSameSlab?.length" class="list" fxFlex="100%">
        <h3 class="red-fg m-0 font-weight-600">
          Rate slab changed (New rate is lower than Initial Rate) (Total:{{
            response?.awbInSameSlab?.length
          }})
        </h3>
        <p class="mt-8">
          <span *ngFor="let awb of response?.awbInSameSlab; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <div *ngIf="response?.awbNotUpdated?.length" class="list" fxFlex="100%">
        <h3 class="red-fg m-0 font-weight-600">
          Weight Discrepancy Not Updated (Total:{{
            response?.awbNotUpdated?.length
          }})
        </h3>
        <p class="mt-8">
          <span *ngFor="let awb of response.awbNotUpdated; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <!-- for COD Remmitance -->
      <div *ngIf="response?.alreadyDone?.length" class="list" fxFlex="100%">
        <h3 class="red-fg m-0 font-weight-600">
          Remittance Already Done (Total:
          {{ response?.alreadyDone?.length }})
        </h3>

        <p class="mt-8">
          <span *ngFor="let awb of response?.alreadyDone; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <div *ngIf="response?.notUpdated?.length" class="m-12 list" fxFlex>
        <h3 class="red-fg m-0 font-weight-600">
          Remittance Not Updated (Total:{{ response?.notUpdated?.length }})
        </h3>
        <p class="mt-8">
          <span *ngFor="let awb of response?.notUpdated; last as last">
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>

      <div
        *ngIf="response?.orderToRemittNotFound?.length"
        class="m-12 list"
        fxFlex
      >
        <h3 class="red-fg m-0 font-weight-600">
          Remmitance Not Found (Total:{{
            response?.orderToRemittNotFound?.length
          }})
        </h3>
        <p class="mt-8">
          <span
            *ngFor="let awb of response?.orderToRemittNotFound; last as last"
          >
            {{ awb }}<span *ngIf="!last">,</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
