import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RupeePaisePipe } from "./rupee-paise.pipe";
import { StatusPipe } from "./status.pipe";
import { PaymentTypePipe } from "./payment-type.pipe";
import { ReasonSummaryPipe } from "./reason-summary.pipe";
import { WeightConversionPipe } from "./weight-conversion.pipe";
import { LostRefundPipe } from "./lost-refund.pipe";
import { WeightDetailsPipe } from "./weight-details.pipe";
import { BreakupDetailsPipe } from "./breakup-details.pipe";
import { ReducedTextPipe } from "./reduced-text.pipe";
import { TatPipe } from "./tat.pipe";
import { SimplifiedZoneNamePipe } from "./simplified-zone-name.pipe";
import { PriceBreakdownPipe } from "./price-breakdown.pipe";
import { VasPermissionsSimplifiedNamesPipe } from "./vas-permissions-simplified-names.pipe";
import { RemainingDaysPipe } from "./remaining-days.pipe";
import { PerUnitConversionPipe } from "./per-unit-conversion.pipe";
import { GetFloorDataPipe } from "./get-floor-data.pipe";
import { CamelToStartCasePipe } from "./camel-to-start-case.pipe";
import { VerifyStatusPipe } from "./verify-status.pipe";
import { UnitTypeConversionPipe } from "./unit-type-conversion.pipe";

@NgModule({
  declarations: [
    RupeePaisePipe,
    StatusPipe,
    PaymentTypePipe,
    ReasonSummaryPipe,
    WeightConversionPipe,
    LostRefundPipe,
    WeightDetailsPipe,
    BreakupDetailsPipe,
    ReducedTextPipe,
    TatPipe,
    SimplifiedZoneNamePipe,
    PriceBreakdownPipe,
    VasPermissionsSimplifiedNamesPipe,
    RemainingDaysPipe,
    PerUnitConversionPipe,
    GetFloorDataPipe,
    CamelToStartCasePipe,
    VerifyStatusPipe,
    UnitTypeConversionPipe
  ],
  imports: [CommonModule],
  exports: [
    RupeePaisePipe,
    StatusPipe,
    PaymentTypePipe,
    ReasonSummaryPipe,
    WeightConversionPipe,
    LostRefundPipe,
    WeightDetailsPipe,
    BreakupDetailsPipe,
    ReducedTextPipe,
    TatPipe,
    SimplifiedZoneNamePipe,
    PriceBreakdownPipe,
    VasPermissionsSimplifiedNamesPipe,
    RemainingDaysPipe,
    PerUnitConversionPipe,
    GetFloorDataPipe,
    CamelToStartCasePipe,
    VerifyStatusPipe,
    UnitTypeConversionPipe
  ]
})
export class PipesModule {}
