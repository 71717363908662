import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { ToolbarNotificationModule } from "app/main/ui/toolbar-notification/toolbar-notification.module";

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,

    // material
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,

    // shared
    ToolbarNotificationModule,

    // fuse
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {}
