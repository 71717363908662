import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { LoginService } from "app/main/shared/services/login.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.services";
import { navigationConfig } from "app/navigation/navigationObjConfig";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { skipWhile, take, takeUntil } from "rxjs/operators";
import { SidebarNavigationService } from "app/main/shared/services/sidebar-navigation.service";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  // Private
  _variant: string;

  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   * @param {Renderer2} _renderer
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Variant
   */
  get variant(): string {
    return this._variant;
  }

  @Input()
  set variant(value: string) {
    // Remove the old class name
    this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

    // Store the variant value
    this._variant = value;

    // Add the new class name
    this._renderer.addClass(this._elementRef.nativeElement, value);
  }

  /**
   * Constructor
   */
  constructor(
    private _loginService: LoginService,
    private _fuseNavigationService: FuseNavigationService,
    private _profileDataService: ProfileDataService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _sidebarNavigationService: SidebarNavigationService
  ) {
    // Set the private defaults
    this._variant = "vertical-style-1";
  }

  ngOnInit(): void {
    const userLoggedIn = !!this._loginService.getToken();

    if (userLoggedIn) {
      this.handlePermissionSub();
      this._sidebarNavigationService.getCounts();
      this._sidebarNavigationService.getTrackingCounts();
    }
  }

  handlePermissionSub(): void {
    this._profileDataService.userPermissions
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val).length),
        take(1)
      )
      .subscribe(({ permissions }) => this.pushItemsToNav(permissions));
  }

  pushItemsToNav(permissionsObj: any = {}) {
    const sideNav = this.createSideNav(permissionsObj);

    for (const item of sideNav) {
      this._fuseNavigationService.addNavigationItem(item, "end");
    }

    // after navigation is created
    this.handleModuleCountSub();
    this.handleTrackingCountSub();
  }

  pushRemainingUrls(permissionsObj, authorisedUrls) {
    if (permissionsObj?.transactionsPermission?.remittance) {
      authorisedUrls.push(
        "/transactions/codremit/remit-to-sg/upload",
        "/transactions/codremit/remit-to-user/upload"
      );
    }

    if (permissionsObj?.requestsPermissions?.weightDiscrepancy) {
      authorisedUrls.push(
        "/request/weight-discrepancy/update",
        "/request/weight-discrepancy/upload"
      );
    }
  }

  createSideNav(permissionsObj: any = {}) {
    const finalNav = [];
    const authorisedUrls = [];

    function transformSideNavItems(
      permissionsObj: any = {},
      parentObj: any = {}
    ) {
      for (const [key, value] of Object.entries(permissionsObj)) {
        if (!value) {
          console.warn(`You don't have permission for id:${key}.`);
          continue;
        }

        const navItem = _.cloneDeep(navigationConfig[key]);

        if (!navItem) {
          console.warn(`Navigation with id:${key} not found.`);
          continue;
        }

        if (navItem.url) {
          authorisedUrls.push(navItem.url);
        }

        if (navItem.type === "group") {
          finalNav.push(navItem);
        } else {
          parentObj.children.push(navItem);
        }

        if (typeof value === "object") {
          let newParentObj: any = {};

          if (navItem.type === "collapsable") {
            newParentObj = parentObj.children[parentObj.children.length - 1];
            transformSideNavItems(value, newParentObj);
          } else {
            newParentObj = finalNav[finalNav.length - 1];
            transformSideNavItems(value, newParentObj);
          }
        }
      }
    }

    transformSideNavItems(permissionsObj);

    function removeEmptyParents(navigation: any[]) {
      navigation.forEach((navItem, index) => {
        if (navItem.children) {
          if (!navItem.children.length) {
            navigation.splice(index, 1);
          } else {
            removeEmptyParents(navItem.children);
          }
        }
      });
    }

    removeEmptyParents(finalNav);
    this.pushRemainingUrls(permissionsObj, authorisedUrls);
    this._profileDataService._authorisedRoutes.next(authorisedUrls);

    return finalNav;
  }

  handleTrackingCountSub() {
    this._sidebarNavigationService.trackingDataCount
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val).length)
      )
      .subscribe((countObj) => this.patchCountToSidenav(countObj));
  }

  handleModuleCountSub(): void {
    this._sidebarNavigationService.moduleDataCount
      .pipe(
        takeUntil(this._unsubscribeAll),
        skipWhile((val) => !Object.keys(val).length)
      )
      .subscribe((countObj) => this.patchCountToSidenav(countObj));
  }

  patchCountToSidenav(countObj: any): any {
    for (const [navigationItem, count] of Object.entries(countObj)) {
      this._fuseNavigationService.updateNavigationItem(navigationItem, {
        badge: {
          title: count || 0,
          bg: "#525e8a",
          fg: "#FFFFFF"
        }
      });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._loginService.clearObservables();

    this._fuseNavigationService.clearNavigation();
  }
}
