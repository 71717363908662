import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { SmsLogsComponent } from "./component/sms-logs.component";

@NgModule({
    declarations: [SmsLogsComponent],
    imports: [
        // fuse
        FuseSharedModule,

        // material
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
    ],
})
export class SmsLogsModule {}
