import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class OrdersDataService {
    // for refresh orders
    updateOrders: Subject<any> = new Subject();

    refreshOrders = () => this.updateOrders.next(true);
}
