import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToolbarNotification } from "app/main/shared/interfaces/toolbar-notificaton";
import { RequestFeedbackComponent } from "app/main/shared/modals/request-feedback/component/request-feedback.component";
import { FullScreenLoaderService } from "app/main/shared/modules/full-screen-loader/full-screen-loader.service";
import { NotificationService } from "app/main/shared/services/notification.service";
import { OrdersDataService } from "app/main/shared/services/orders-data.service";
import { ProfileDataService } from "app/main/shared/services/profile-data.services";
import { BehaviorSubject, Subject, timer } from "rxjs";
import {
  concatMap,
  delay,
  retryWhen,
  share,
  switchMap,
  take,
  takeUntil
} from "rxjs/operators";
import { BulkProcessComponent } from "../../../shared/modals/bulk-process/component/bulk-process.component";
import { ToolbarNotificationService } from "../../toolbar-notification.service";

@Component({
  selector: "toolbar-notification",
  templateUrl: "./toolbar-notification.component.html",
  styleUrls: ["./toolbar-notification.component.scss"]
})
export class ToolbarNotificationComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject();
  isOpen: boolean;
  notifications: any[] = [];
  load$ = new BehaviorSubject("");
  refreshed: boolean = false;
  profileData: any[];

  constructor(
    private _toolbarNotification: ToolbarNotificationService,
    private _router: Router,
    private _ordersDataService: OrdersDataService,
    private _dialog: MatDialog,
    private _profileDataService: ProfileDataService,
    private _fullScreenLoader: FullScreenLoaderService,
    private _notification: NotificationService,
    private _http: HttpClient
  ) {}

  ngOnInit(): void {
    this.handleNotificationChange();
    this.fetchNotifications();
    this.fetchProfileData();
  }

  fetchProfileData() {
    this._profileDataService.profileData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profileObj) => {
        this.profileData = profileObj;
      });
  }

  refreshNotifications() {
    this.refreshed = true;
    this.load$.next("");
  }

  handleNotificationChange() {
    this._toolbarNotification.onNotificationUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.notifications = this._toolbarNotification.get();
      });
  }

  fetchNotifications() {
    const oneMinute = 60 * 1000;
    const fiveMinute = 60 * 5000;
    this.load$
      .pipe(
        switchMap(() =>
          timer(0, fiveMinute).pipe(
            concatMap(() => this._toolbarNotification.getNotifications()),
            retryWhen((errors) => errors.pipe(delay(oneMinute * 3), take(3))),
            share()
          )
        ),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: any) => {
        this.refreshed = false;
        if (res.success) {
          this.pushNotifications(res.data);
        }
      });
  }

  pushNotifications(notifications: ToolbarNotification[]) {
    notifications.forEach((notification) => {
      let { link, icon, action, previousId } = notification;

      if (link) {
        icon = "link";
        action = "openURL";
      }

      if (action === "refreshOrder") {
        icon = "update";
      }

      if (!icon) {
        icon = "help_outline";
      }

      this._toolbarNotification.add({ ...notification, icon, action });

      if (previousId) {
        this.dismiss(undefined, previousId);
      }
    });
  }

  onClickOutside(): void {
    this.isOpen = false;
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  takeAction(notification: ToolbarNotification) {
    const {
      SG_ORDERID,
      url,
      link,
      action,
      title,
      weightDescTaskId,
      codRemitTaskId,
      previousId,
      _id
    } = notification;
    let trackingData: any = {};

    switch (action) {
      case "changeRoute":
        this._router.navigateByUrl(url);
        break;

      case "openURL":
        window.open(link, "_blank");
        break;

      case "refreshOrder":
        this._ordersDataService.refreshOrders();
        this.clearSpecificActionNotifications(notification);
        break;

      case "openProcessModal":
        this._dialog.open(BulkProcessComponent, {
          panelClass: "medium-modal",
          data: { ...this.profileData, title, weightDescTaskId, codRemitTaskId }
        });
        if (previousId) {
          this.dismiss(undefined, _id);
        }
        break;

      case "navigateToCodRecharge":
        this._router.navigate(["transactions", "recharge-cod"]);
        this.dismiss(undefined, _id);
        break;

      case "feedback":
        this._router.navigateByUrl("orders/customer-response/feedback");
        trackingData = {
          type: "feedback",
          SG_ORDERID
        };
        this.trackingActions(trackingData);
        break;
      case "requests":
        this._router.navigateByUrl("orders/customer-response/request");
        trackingData = {
          type: "requests",
          SG_ORDERID
        };
        this.trackingActions(trackingData);
        break;

      default:
        break;
    }
  }

  trackingActions(body) {
    this._fullScreenLoader.show();
    this._http
      .post("admin/deliveryManager/getsingleorder", body)
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          const { type } = body;
          const heading = type === "requests" ? "Request" : "Feedback";
          this._dialog.open(RequestFeedbackComponent, {
            panelClass: "medium-modal",
            data: {
              heading,
              type,
              ...res.data
            }
          });
        } else {
          this._notification.show(res?.message || res?.msg);
        }
      });
  }

  // clear Specific Action type notification
  clearSpecificActionNotifications({ action }: ToolbarNotification) {
    const notificationIds = this.notifications.filter(
      (notification: ToolbarNotification) => {
        if (notification.action === action) {
          this._toolbarNotification.dismiss(notification._id);
          return true;
        }

        return false;
      }
    );

    this.markAsRead(notificationIds);
  }

  // to dismiss particular notification
  dismiss(event, id: string): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.markAsRead([id]);
    this._toolbarNotification.dismiss(id);
  }

  // to clear all notifications
  clearNotifications(): void {
    let notificationIds: any[] = [];
    let nonClearableNotifications: any[] = [];

    if (!this.notifications.length) return;

    this.notifications.forEach((notification: ToolbarNotification) => {
      notification?.canNotBeCleared
        ? nonClearableNotifications.push(notification)
        : notificationIds.push(notification._id);
    });

    this.markAsRead(notificationIds);
    this._toolbarNotification.removeClearableNotifications(
      nonClearableNotifications
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  markAsRead(notificationIds: string[]) {
    this._toolbarNotification.markAsRead({ notificationIds }).subscribe(() => {
      // do nothing
    });
  }
}
